//react
import React from "react";
//mui components
import Grid from '@mui/material/Grid';

const RocketSvg = ({ onClick, ...props }) => {
	const sx = { backgroundColor: "none" };
	if (onClick) {
		sx.cursor = "pointer"
	}
	return (
		<Grid container sx={sx} onClick={onClick}>
			<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="1" y="2.26953" width="34" height="32.7303" rx="16.3652" fill="#D9D9D9" />        
						
        <g clipPath="url(#clip0_2935_4609)">
				<rect x="1" y="1" width="32.4186" height="30.8736" rx="15.4368" fill="white"/>
				  <circle cx="23" cy="22" r="20" fill="#C3D7F2"/>
			</g>
			<rect x="0.5" y="0.5" width="33.4186" height="31.8736" rx="15.9368" stroke="#1F1F1F" strokeOpacity="0.6"/>
        <path d="M25.8054 8.58376C25.7985 8.55106 25.7824 8.52098 25.7588 8.49705C25.7352 8.47312 25.7052 8.45635 25.6724 8.44869C23.4805 7.91877 18.4171 9.80697 15.671 12.5195C15.1816 12.9994 14.7353 13.5203 14.3372 14.0766C13.4908 14.0009 12.6443 14.0647 11.9237 14.3755C9.88855 15.26 9.29644 17.5701 9.13118 18.5618C9.12223 18.6162 9.12605 18.6718 9.14236 18.7245C9.15867 18.7771 9.18702 18.8253 9.22521 18.8654C9.26341 18.9055 9.31043 18.9363 9.36262 18.9555C9.41482 18.9747 9.47079 18.9818 9.52619 18.9762L12.7939 18.6216C12.7961 18.8652 12.8109 19.1086 12.8382 19.3507C12.8553 19.5181 12.9308 19.6744 13.0518 19.7926L14.3171 21.0433C14.4368 21.163 14.595 21.2377 14.7645 21.2545C15.008 21.2813 15.2527 21.2959 15.4977 21.2983L15.1393 24.5256C15.1337 24.5803 15.141 24.6356 15.1604 24.6871C15.1798 24.7386 15.211 24.785 15.2515 24.8227C15.292 24.8604 15.3407 24.8884 15.3938 24.9045C15.447 24.9207 15.5032 24.9245 15.5581 24.9157C16.5618 24.7563 18.9036 24.171 19.7932 22.1597C20.1076 21.4465 20.1721 20.6142 20.0995 19.7815C20.6639 19.3883 21.1925 18.9472 21.6792 18.463C24.4353 15.7536 26.3334 10.8584 25.8054 8.58376ZM19.2293 14.9532C18.9886 14.7153 18.8246 14.4121 18.7582 14.0821C18.6917 13.752 18.7258 13.4099 18.856 13.099C18.9863 12.7881 19.2069 12.5224 19.4899 12.3355C19.773 12.1485 20.1057 12.0487 20.4462 12.0487C20.7866 12.0487 21.1194 12.1485 21.4024 12.3355C21.6854 12.5224 21.906 12.7881 22.0363 13.099C22.1666 13.4099 22.2006 13.752 22.1342 14.0821C22.0677 14.4121 21.9038 14.7153 21.663 14.9532C21.5034 15.1114 21.3137 15.2369 21.1049 15.3225C20.8961 15.4081 20.6722 15.4522 20.4462 15.4522C20.2201 15.4522 19.9963 15.4081 19.7875 15.3225C19.5786 15.2369 19.389 15.1114 19.2293 14.9532Z" fill="white" stroke="black" strokeWidth="0.576928" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.6101 20.4648C11.2083 20.5136 10.8345 20.6937 10.548 20.9764C9.83782 21.6809 9.77051 24.2898 9.77051 24.2898C9.77051 24.2898 12.4114 24.2233 13.122 23.5205C13.4093 23.2377 13.5917 22.8676 13.64 22.4698" stroke="black" strokeWidth="0.576928" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
          <clipPath id="clip0_2935_4609">
					<rect x="1" y="1" width="32.4186" height="30.8736" rx="15.4368" fill="white"/>
				</clipPath>
			</defs>
		</svg>
		</Grid>

	);
}

export default RocketSvg;