//react
import React from "react";
//mui components
import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
//sqlitecloud hooks
import { useGetProjectInfo, useGetDatabases, useGetProjectApiKey } from "@custom-hooks/useApi";

//sqlitecloud components
import Card from "@generic-components/Card";
import ModalContent from "@generic-components/ModalContent";
import CopyToClipboardButton from "@generic-components/CopyToClipboardButton";

const useBuildConnectionString = (projectId) => {
	const { databases, showLoader: showLoaderDatabase } = useGetDatabases(projectId);
	const { connectionStringApiKey, showLoader: showLoaderProjectInfo } = useGetProjectInfo(projectId);
	const { projectApiKey, showLoader: showLoaderApiKey } = useGetProjectApiKey(projectId);

	const databaseOptions = React.useMemo(() => {
		return databases?.map((database) => ({ label: database.name, value: database.name })) ?? [];
	}, [databases]);

	const apiKeyOptions = React.useMemo(() => {
		return projectApiKey?.map((apiKey) => ({ label: apiKey.name, value: apiKey.key })) ?? [];
	}, [projectApiKey]);
	const [selectedApiKey, setSelectedApiKey] = React.useState("");
	const [selectedDatabase, setSelectedDatabase] = React.useState("");

	React.useEffect(() => {
		if (databaseOptions?.length && apiKeyOptions?.length) {
			setSelectedDatabase(databaseOptions[0]?.value)
			setSelectedApiKey(apiKeyOptions[0]?.value)
		}
	}, [databaseOptions, apiKeyOptions])

	const [connStr,] = connectionStringApiKey?.split('?') ?? []

	const connectionString = `${connStr}/${selectedDatabase}?apikey=${selectedApiKey}`

	return {
		showLoader: showLoaderApiKey || showLoaderDatabase || showLoaderProjectInfo,
		connectionString,
		databaseOptions,
		selectedDatabase,
		setSelectedDatabase,
		setSelectedApiKey,
		apiKeyOptions,
		selectedApiKey,
	}
}

const SingleProjectInfo = ({ projectId }) => {
	const {
		showLoader,
		connectionString,
		databaseOptions,
		selectedDatabase,
		selectedApiKey,
		setSelectedApiKey,
		apiKeyOptions,
		setSelectedDatabase,
	} = useBuildConnectionString(projectId)
	return (
		<ModalContent
			title={"Connection String"}
			showLoader={showLoader}
		>
			{
				!showLoader && connectionString &&
				<>
					<Stack gap="20px" >
						<TextField
							select={true}
							value={selectedDatabase}
							label={"Database"}
							onChange={(e) => setSelectedDatabase(e.target.value)}
						>
							{
								databaseOptions.map(({ label, value }) => (
									<MenuItem key={label} value={value}>{label}</MenuItem>
								))
							}
						</TextField>
						<TextField
							select={true}
							value={selectedApiKey}
							label={"API Key"}
							onChange={(e) => setSelectedApiKey(e.target.value)}
						>
							{
								apiKeyOptions.map(({ label, value }) => (
									<MenuItem key={label} value={value}>{label}</MenuItem>
								))
							}
						</TextField>
					</Stack>
					<Card customSx={{ padding: "0.5rem 1rem" }}>
						<Box sx={{ mt: 0 }}>
							<CopyToClipboardButton label={connectionString ? connectionString : "..."} value={connectionString ? connectionString : "..."} alignLeft={true} />
						</Box>
					</Card>
				</>

			}
		</ModalContent>
	);
};

export default SingleProjectInfo;