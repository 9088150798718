//react
import React, { Fragment, useRef, useState, useEffect, useContext } from "react";
//swr
import { useSWRConfig } from 'swr';
//css library
import styled from "@emotion/styled";
//mui components
import { spacing } from "@mui/system";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MuiPopover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import MuiLinearProgress from "@mui/material/LinearProgress"
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Server } from "react-feather";
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.neutral.lightGrey};
  }
`;
const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.primary.white};
  }
`;
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud lib
import { getJobStatus } from "@lib/dataUtils";
//sqlitecloud hooks
import { useGetJobNodes, useGetActualProjectId } from "@custom-hooks/useApi";
import { useSetError } from "@custom-hooks/useSetError";
//sqlitecloud components
import Card from "@generic-components/Card";
import ArchiveJob from "./ArchiveJob";
import BellSvg from "@generic-components/SVG/BellSvg"

function JobNotification({ job }) {
	const [error, setError] = useState(null);
	useSetError(error);

	//extract job status
	let status;
	if (job.error == 1) {
		status = "error";
	} else if (job.error == 0) {
		if (job.progress < job.steps) {
			status = "progress";
		}
		if (job.steps == job.progress) {
			status = "completed";
		}
	}
	return (
		<ListItem divider disableGutters >
			<Grid container>
				<Grid
					item
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					wrap="nowrap"
				>
					<ListItemText
						primary={job.name}
						sx={{ overflow: "auto", margin: 0 }}
						secondary={job.status}
					/>
					{
						(status == "completed" || status == "error") &&
						<ArchiveJob jobId={job.uuid} setError={setError} />
					}
				</Grid>
				<Grid item width={"100%"}>
					{
						status == "progress" &&
						<LinearProgress my={2} variant="buffer" color="secondary" value={(job.progress - 1) / job.steps * 100} valueBuffer={(job.progress) / job.steps * 100} />
					}
				</Grid>
			</Grid>
		</ListItem>
	);
}

function JobsDropDown({ }) {
	//get actual project id
	const projectId = useGetActualProjectId();
	//handle JobsDropDown visualitazion state
	const { isOpenJobsDropDown, setIsOpenJobsDropDown, jobsRefreshInterval, setJobsRefreshInterval } = useContext(StateContext);
	//create ref to opening button for dropdown
	const jobButtonRef = useRef(null);
	useEffect(() => {
		if (isOpenJobsDropDown && jobButtonRef.current) {
			jobButtonRef.current.click();
		}
	}, [isOpenJobsDropDown])
	//handle jobs dropdown opeining
	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
		mutate([`/api/projects/${projectId}/jobs/nodes`, "useGetJobNodes"]);
		setIsOpenJobsDropDown(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setIsOpenJobsDropDown(false);
	};
	//swr
	const { mutate } = useSWRConfig();
	//read jobs nodes
	const getJobsNodes = useGetJobNodes(jobsRefreshInterval);
	//the useRef Hook allows you to persist jobs status between renders
	const prevJobsStatusRef = useRef();
	useEffect(() => {
		if (getJobsNodes.jobNodes) {
			var testIsInProgress = false;
			var jobsStatus = new Map();
			getJobsNodes.jobNodes.forEach(job => {
				//extract job status
				let status = getJobStatus(job);
				if (prevJobsStatusRef.current && prevJobsStatusRef.current.get(job.uuid) == "progress" && status == "completed") {
					mutate([`/api/projects/${projectId}/nodes`, "useGetNodes"]);
					mutate([`/api/projects`, "useGetProjects"]);
					setIsOpenJobsDropDown(true);
				}
				if (prevJobsStatusRef.current && prevJobsStatusRef.current.get(job.uuid) == "progress" && status == "error") {
					setIsOpenJobsDropDown(true);
				}
				jobsStatus.set(job.uuid, status);
				if (status !== "completed" && status !== "error") {
					testIsInProgress = true;
				}
			})
			prevJobsStatusRef.current = jobsStatus;
			if (testIsInProgress) {
				setJobsRefreshInterval(10000);
			} else {
				setJobsRefreshInterval(false);
			}
		}
	}, [getJobsNodes, projectId, mutate])

	//loader condition 
	const isLoading = getJobsNodes.isLoading;
	const showLoader = (getJobsNodes.isLoading || getJobsNodes.isValidating);
	//error condition
	const jobsNodesError = getJobsNodes.isError;
	const showError = jobsNodesError;
	//data condition
	const jobNodes = getJobsNodes.jobNodes;
	//disable
	const disabled = showError || isLoading || !jobNodes || (jobNodes && jobNodes.length == 0);
	//render
	const renderJobs = (jobNodes && Array.isArray(jobNodes) && jobNodes.length > 0) ? jobNodes.slice(0).reverse() : null;
	return (
		<Fragment>
			{
				projectId && false &&
				<>
					<Tooltip title="Jobs">
						<span>
							<IconButton
								ref={jobButtonRef}
								disabled={disabled}
								sx={{ padding: 0 }}
								onClick={handleOpen}
								color="primary"
								disableFocusRipple={true}
								disableRipple={true}
							>
								<Indicator badgeContent={jobNodes ? jobNodes.length : 0}>
									<BellSvg />
								</Indicator>
							</IconButton>
						</span>
					</Tooltip>
					{
						renderJobs && !isLoading && !showError && anchorEl &&
						<Popover
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							PaperProps={{
								style: { border: "none", marginTop: "1rem", marginLeft: "-0.5rem", background: "transparent" }
							}}
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<Card type={"info-1"} customSx={{ padding: "0.5rem 1rem" }}>
								{
									renderJobs.map((job, i) => {
										return (
											<JobNotification
												key={job.uuid}
												job={job}
												Icon={Server}
											/>
										)
									})
								}
							</Card>
						</Popover>
					}
				</>
			}
		</Fragment>
	);
}

export default JobsDropDown;
