'use client';
//react
import { useContext, useState } from "react";
//posthog
import { usePostHog } from 'posthog-js/react';
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud components
import { Dialog } from '@headlessui/react';
import { Button, DialogTitle } from '@mui/material';
import RocketSvg from "@components/GenericElements/SVG/RocketSvg";

function UpgradeModal({
	isOpen,
	setIsOpen
}) {
	const posthog = usePostHog();
	const { userInfo } = useContext(StateContext);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const handleSub = () => {
		posthog.capture('subscribe:notify_pro');
		setIsSubscribed(true);
	}

	return (
		<Dialog className={"tw-relative tw-z-[1205]"} open={isOpen} onClose={() => setIsOpen(false)}>
			<div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto tw-bg-opacity-20 tw-bg-mediumblue">
				<div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
					<Dialog.Panel className="tw-w-full tw-max-w-lg tw-transform tw-overflow-hidden tw-rounded-2xl tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all">
						<div className="tw-flex tw-flex-col tw-gap-2">
							<RocketSvg onClick={null} />
							{
								!isSubscribed && (
									<>
										<div className="tw-mb-6 tw-mt-3">
											<Dialog.Title className="tw-text-32px-light tw-mb-6">Upgrade to SQLite Cloud Pro</Dialog.Title>
											<Dialog.Description className="tw-mt-2 tw-text-13px-reg tw-text-gray-700">Hey {userInfo.first_name}! Thank you for your interest in SQLite Cloud Pro.</Dialog.Description>
											<Dialog.Description className="tw-mt-2 tw-text-13px-reg tw-text-gray-700">SQLite Cloud is currently in beta, but we will launch our Pro plan (including local-first sync) in the coming weeks.</Dialog.Description>
											<Dialog.Description className="tw-mt-2 tw-text-13px-reg tw-text-gray-700">Subscribe to get notified when we launch SQLite Cloud Pro!</Dialog.Description>
										</div>
										<div className="tw-flex tw-w-full tw-justify-between">
											<Button onClick={() => setIsOpen(false)} sx={{ backgroundColor: '#D0E2F8', color: '#1F1F1F', '&:hover': { backgroundColor: '#B0C2D8', color: '#1F1F1F' } }}>Close</Button>
											<Button onClick={handleSub} className="tw-mt-4">Subscribe</Button>
										</div>
									</>
								)
							}
							{
								isSubscribed && (
									<div className="tw-mb-3 tw-mt-3">
										<Dialog.Title className="tw-text-32px-light tw-mb-6">Upgrade to SQLite Cloud Pro</Dialog.Title>
										<Dialog.Description className="tw-mb-6 tw-text-13px-reg tw-text-gray-700">Thanks for subscribing! We'll notify you when SQLite Cloud Pro is ready.</Dialog.Description>
										<Button onClick={() => setIsOpen(false)}>Close</Button>
									</div>
								)
							}
						</div>
					</Dialog.Panel>
				</div>
			</div>
		</Dialog>
	)
}



UpgradeModal.displayName = 'UpgradeModal';


export { UpgradeModal };