//react
import React, { useRef } from "react";
//mui components
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
//sqlitecloud components
import ArrowSvg from "@generic-components/SVG/ArrowSvg";
import Feedback from "@generic-components/Feedback";


const ContactSupportAction = ({ text = "Contact us to upgrade your Beta instance", feedbackFormContent }) => {
	const feedbackRef = useRef(null);
	const handleOpenFeedback = () => {
		feedbackRef.current.open();
	}
	return (
		<Grid container alignItems="center" justifyContent="space-between">
			<Grid item>
				<Typography as="div" variant="14px-light" align="left">
					{text}
				</Typography>
			</Grid>
			<Grid item>
				<ArrowSvg onClick={handleOpenFeedback} />
			</Grid>
				<Feedback feedbackRef={feedbackRef} content={feedbackFormContent} />
		</Grid>
	);
}

export default ContactSupportAction;
